body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


  #primary-nav {
    background-color: #68de09;
    height: 56px;
  }
  .navbar {
    background-color: #68de09;
  }
  .navbar .navbar-brand {
    color: #000000;
  }
  .navbar .navbar-brand:hover,
  .navbar .navbar-brand:focus {
    color: #000000;
  }
  .navbar .navbar-text {
    color: #000000;
  }
  .navbar .navbar-text a {
    color: #000000;
  }
  .navbar .navbar-text a:hover,
  .navbar .navbar-text a:focus {
    color: #000000; 
  }
  .navbar .navbar-nav .nav-link {
    color: #000000;
    border-radius: .25rem;
    margin: 0 0.25em;
  }
  .navbar .navbar-nav .nav-link:not(.disabled):hover,
  .navbar .navbar-nav .nav-link:not(.disabled):focus {
    color: #000000;
  }
  .navbar .navbar-nav .nav-item.active .nav-link,
  .navbar .navbar-nav .nav-item.active .nav-link:hover,
  .navbar .navbar-nav .nav-item.active .nav-link:focus,
  .navbar .navbar-nav .nav-item.show .nav-link,
  .navbar .navbar-nav .nav-item.show .nav-link:hover,
  .navbar .navbar-nav .nav-item.show .nav-link:focus {
    color: #000000;
    background-color: #73c52f;
  }
  .navbar .navbar-toggle {
    border-color: #73c52f;
  }
  .navbar .navbar-toggle:hover,
  .navbar .navbar-toggle:focus {
    background-color: #73c52f;
  }
  .navbar .navbar-toggle .navbar-toggler-icon {
    color: #000000;
  }
  .navbar .navbar-collapse,
  .navbar .navbar-form {
    border-color: #000000;
  }
  .navbar .navbar-link {
    color: #000000;
  }
  .navbar .navbar-link:hover {
    color: #000000;
  }
  
  @media (max-width: 575px) {
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #000000;
    }
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color: #73c52f;
    }
  }
  
  @media (max-width: 767px) {
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #000000;
    }
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color: #73c52f;
    }
  }
  
  @media (max-width: 991px) {
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #000000;
    }
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color: #73c52f;
    }
  }
  
  @media (max-width: 1199px) {
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item {
      color: #000000;
    }
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #000000;
    }
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item.active {
      color: #000000;
      background-color: #73c52f;
    }
  }
  
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #000000;
  }
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #000000;
  }
  .navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #000000;
    background-color: #73c52f;
  }
